/* You can add global styles to this file, and also import other style files */
@import "~primeng/resources/primeng.min.css";
/*@import "~primeicons/primeicons.css";*/
//@import "~primeflex/primeflex.css";
@import "~primeng/resources/themes/saga-blue/theme.css";
@import "~primeicons/primeicons.css";
@import "~primeflex/primeflex.css";
@import "~primeng/resources/themes/saga-blue/theme.css";


:root{

    --sidenavWidth: 270px;

   /*  @media screen and (min-width: 1600px){
      --sidenavWidth: 15vw;
    } */

    @media only screen and (max-width: 768px) {
    
            --sidenavWidth: 29.8vw;
        
      }
  
}

/*=========== Datatable ================*/
.p-datatable{
    .p-datatable-tbody > tr > td,
    .p-datatable-thead > tr > th{
        font-size: 12px;
        text-align: center;

        &:nth-child(2){
            text-align: left;
        }
    }

    .p-datatable-thead > tr > th,
    .p-datatable-tbody > tr > td:nth-child(2){
        text-transform: uppercase;
        font-weight: 500;
        color: #00303a;
    }
    
}

.ecumeTabview .p-tabview-panels{
    padding: 0;
    background: #fcfcfc;
}

.ecumeTabview .user-table{
    border-left: 3px solid #1fbfbf;
    font-size: 12px;
    text-align: left;
}

.row-expanded-td{
    background: #f5f5f5;
}

.p-input-icon-left > .p-inputtext{
    padding-left: 2.5rem;
}

.sub-labels {
    font-weight: bold;
    padding: 5px 10px;
    margin-right: 2px;
}

/*=========== Form Elements ================*/

.form-control,
.p-dropdown .p-dropdown-label.p-inputtext,
.p-multiselect .p-multiselect-label,
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    font-size: 0.875rem;
}

.form-control {
    border-radius: 3px;
}

.form-group {
    font-size: 0.875rem;
}

.p-dropdown .p-dropdown-label.p-inputtext,
.p-multiselect .p-multiselect-label {
    padding: 6px 12px;
}

.p-dropdown .p-dropdown-trigger .p-icon-wrapper svg{
    height: 14px;
    width: 14px;
}

.p-datatable .p-sortable-column .p-sortable-column-icon{
    height: 0.8rem;
    margin-top: -3px;
    margin-left: 0.25rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding: 0.375rem 0.75rem;
    padding-right: 1.5rem;
}

.btn{
    border-radius: 0.2rem;        
    padding: 1rem;
    font-weight: bold;
    line-height: 0.9;
}

.btn-primary{
    border: 0;

    &, &:disabled{
        background: #58d6d6;       
    }

    .pi{
        font-size: 12px;
        font-weight: bold;
        margin-right: 7px;
    }

    &:hover,
    &:focus {
        background: #51c4c4;
    }

    &:first-child:active{
        background: #3fa7a7;
    }
}


.btn-outline-primary{
    --bs-btn-color: #4fc3c3;
    --bs-btn-border-color: #4fc3c3;
    --bs-btn-hover-bg: #58d6d6;
    --bs-btn-hover-border-color: #58d6d6;
    --bs-btn-active-bg: #3fa7a7;
    --bs-btn-active-border-color: #3fa7a7;
    --bs-btn-disabled-color: #c2c2c2;
    --bs-btn-disabled-border-color: #c2c2c2;
}

.mh-100 {
    height: 100%;
}

.p-component {
    @media only screen and (max-width: 1440px) {
        font-size: 0.875rem!important;
    }
}

.p-component-overlay-enter {
    animation: p-component-overlay-enter-animation 800ms forwards;
}

.p-component-overlay {
    transition-duration: 0.9s;
}



.body-sidenav-collapsed,
.sidenav--collapse {  
    --sidenavWidth: 142px;
}
