@import "root/variable";

html {
    margin: 0;
    padding: 0;
    font-size: $root-font-size-default;
  }
  
  @media screen and (max-width: 1440px) {
    html {
      font-size: $font-size-s;
    }
  }
  
  body {
    color: $clr-grey-dark;
    font-family: $font-family-regular;
    font-size: $font-size-regular;
    line-height: 1.5;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 1440px) {
        font-size: $font-size-s;
    }
  }

body,
.p-component {
    font-family: $font-family-regular;
}

ul, ol {
    padding: 0;
    margin: 0;
}

.btn-primary {
    padding: 1.4rem 2.2rem;
    background: $clr-primary;
    color: $clr-white;
    font-size: 1rem;
    letter-spacing: 0.08em;
    border-radius: 4px;
    text-transform: uppercase;
    border: none;
    box-shadow: inset -1px 0px 0px 0px $clr-primary, inset 1px 0px 0px 0px $clr-primary, inset 0px -1px 0px 0px $clr-primary, inset 0px 1px 0px 0px $clr-primary;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);

    &:hover {
        color: $clr-primary;
        background: $clr-white;
    }
}

.p-datatable {
    .p-datatable-table {
        border: 0 !important;
    }

    .p-datatable-thead {
        >tr {
            >th {
                color: $clr-white;
                background: #007175;
            }
        }
    }

    .p-sortable-column{
        &:focus{
            box-shadow: none;
        }
        &.p-highlight{
            background: #025255;
            color: $clr-white;

            &:hover {
                background: #025255;
                color: $clr-white;
            }
        }
        &:not(.p-highlight){
            &:hover {
                background: #025255;
                color: $clr-white;
            }
        }
        .p-sortable-column-icon{
            color: $clr-white !important;
        }
    }

    .pi {
        font-size: 1.1rem;
    }
}


.p-tabview {
    .p-tabview-nav {
        border-bottom: 2px solid $clr-black;
        li {
            margin-right: 0.4rem;
            .p-tabview-nav-link{
                color: $clr-black;
                border: 1px solid $clr-white;
                border-radius: 0;
                margin: 0;
                border-bottom: 0;
                background: $clr-white;
                padding: 1.2rem 1.5rem;
                text-transform: uppercase;    
                letter-spacing: 0.08em;
                transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
                &:not(.p-disabled):focus{
                    box-shadow: none;
                }

                // &:hover{
                //     background: $clr-black;
                //     color: $clr-white;
                // }
            }
            &.p-highlight, &:hover {
                .p-tabview-nav-link {
                    background: rgba(31, 191, 191, 0.9) !important;
                    color: $clr-white !important;
                    border-color: rgba(31, 191, 191, 0.9) !important;
                }
            }
        }
    }
}

.p-label-warning {
    color: #D32F2F;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    border-radius: 50%;
}