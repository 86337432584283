

$clr-black:#000;
$clr-white:#fff;
$clr-primary:#ff6165;
$clr-seconddary:#219CDC;
$clr-orange:#E5531A;
$clr-grey-dark:#495057;
$clr-grey-medium:#6C757D;
$clr-grey-light:#CED4DA;

$font-family-light:'ubuntulight';
$font-family-regular:'ubunturegular';
$font-family-medium:'ubuntumedium';
$font-family-bold:'ubuntubold';

$font-light:300;
$font-regular:400;
$font-medium:500;
$font-bold:700;

$root-font-size-default: 100%;
$root-font-size-large: calc(16 * (1vw / 14.4)); /* 16 on 1440 and 21.3 on 1920 */
$font-size-regular: 1rem;
$font-size-s: 0.875rem;
$font-size-xs: 0.75rem
