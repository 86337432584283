/* ubuntu-300 - latin */
@font-face {
  font-display: swap;
  font-family: 'ubuntulight';
  font-style: normal;
  font-weight: 300;
  src: url('ubuntu-v20-latin-300.woff2') format('woff2'); 
}
/* ubuntu-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'ubunturegular';
  font-style: normal;
  font-weight: 400;
  src: url('ubuntu-v20-latin-regular.woff2') format('woff2'); 
}
/* ubuntu-500 - latin */
@font-face {
  font-display: swap;
  font-family: 'ubuntumedium';
  font-style: normal;
  font-weight: 500;
  src: url('ubuntu-v20-latin-500.woff2') format('woff2'); 
}
/* ubuntu-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'ubuntubold';
  font-style: normal;
  font-weight: 700;
  src: url('ubuntu-v20-latin-700.woff2') format('woff2'); 
}

